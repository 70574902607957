import { defineStore } from 'pinia';

const useFlashTokenStore = defineStore('flashTokenStore', {
    state: () => ({
        flashAccesToken: '' as string,
    }),
    getters: {
        getAccessToken: (state) => state.flashAccesToken,
    },
    actions: {
        setAccessTokenToStateFromCookie() {
            this.flashAccesToken = this.$nuxt.$cookies.get('flash.id_token');
        },
    },
});

export default useFlashTokenStore;
