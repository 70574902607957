export default (ES_DOMAIN: string = process.env.ES_DOMAIN || 'https://www.energysage.com') => ({
    home: {
        name: 'EnergySage',
        link: ES_DOMAIN,
    },
    mainMenuText: 'Main menu',
    mobileCloseButtonAltText: 'Close',
    mobileNavButtonAltText: 'Open navigation menu',
    seeAllText: 'See all',
    topLevelMenus: [
        {
            name: 'Leads',
            link: `${ES_DOMAIN}/market`,
        },
        {
            name: 'Messages',
            link: `/`,
        },
        {
            name: 'Insights',
            link: `${ES_DOMAIN}/market/insights/`,
        },
        {
            name: 'Ask EnergySage',
            link: `${ES_DOMAIN}/market/installer-help/`,
        },
    ],
});
