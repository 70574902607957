import { defineStore } from 'pinia';
import useFlashTokenStore from '~/store/flashTokenStore';
import { jwtDecode } from 'jwt-decode';

const useUserStore = defineStore('userStore', {
    state: () => ({
        userId: null as string | null,
        userType: null as string | null,
        property_uuid: null as string | null,
    }),
    getters: {
        getUserId: (state) => state.userId,
        getUserType: (state) => state.userType,
        getPropertyUUID: (state) => state.property_uuid,
    },
    actions: {
        setUserId() {
            const flashTokenStore = useFlashTokenStore();
            const decodedToken: any = jwtDecode(flashTokenStore.getAccessToken);
            this.userId = decodedToken['custom:flash_user_id'];
        },

        setUserDetailsFromEsSite() {
            this.$nuxt.$axios.$get(`/api/v1/users/${this.userId}`).then((res: any) => {
                this.userType = res.account_type;
                this.property_uuid = res.property_uuid;
            });
        },
    },
});

export default useUserStore;
